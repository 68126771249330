import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import HeadingMain from '../components/HeadingMain'
import ButtonArrow from '../components/ButtonArrow'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'

const Container = styled.div`
  max-width: ${p => p.theme.maxPageWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${p => p.theme.paddingOnSides};
  padding-right: ${p => p.theme.paddingOnSides};
  display: flex;
  margin-bottom: 90px;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    padding-left: 0;
    padding-right: 0;
  }
`

const Left = styled.div`
  position: relative;
  width: 50%;
  padding-right: 80px;
  @media (max-width: 1000px) {
    width: 100%;
    padding-left: ${p => p.theme.paddingOnSides};
    padding-right: ${p => p.theme.paddingOnSides};
    margin-bottom: 30px;
  }
`

const Right = styled.div`
  width: 50%;
  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 32px;
  }
`

const Heading = styled.h1`
  font-size: 30px;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  color: ${p => p.theme.textColorDark};
  margin-bottom: 24px;
`

const Paragraph = styled.p`
  margin-bottom: 26px;
`

const Portrait = styled(GatsbyImage)`
  width: 40vw;
  min-width: 100%;
  box-shadow: 0 12px 38px ${p => p.theme.lightGray};
  :first-child {
    margin-bottom: 80px;
    @media (max-width: 1000px) {
      margin-bottom: 60px;
    }
    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }
`

const WaterColor = styled(GatsbyImage)`
  position: absolute !important;
  width: 210px;
  opacity: 0.4;
  z-index: -1;
  top: -24px;
  left: -40px;
  @media (max-width: 1000px) {
    width: 170px;
    left: -5px;
    top: -20px;
  }
`

class Lagen extends Component {
  render() {
    const { lagenImage, lagenImage2, watercolorImage } = this.props.data
    return (
      <React.Fragment>
        <SEO
          title="Die Lagen"
          description="Ried Ungerberg und Goldberg verleihen Zweigelt, Blaufränkisch oder Merlot ihre besondere Note. Im Osten des Neusiedlersees wächst eine große Sortenvielfalt heran."
        />
        <HeadingMain mainHeading="Lagen" subHeading="Unsere schöne Herkunft mit seinen ganz besonderen Lagen" />
        <Container>
          <Left>
            <WaterColor alt="" image={watercolorImage.childImageSharp.gatsbyImageData} />
            <Heading>Herkunft – Sonnenanbeter und die Brise des Neusiedlersees</Heading>
            <Paragraph>
              Unsere wunderschöne Heimat ist das Weinbaugebiet Neusiedlersee. Im Osten des Neusiedlersees liegt die
              bedeutendste Weinbaugemeinde Österreichs, Gols. Zwischen sanften Hügeln und der tiefen Ebene reift hier
              eine große Sortenvielfalt heran. Das pannonische Klima, das durch heiße trockene Sommer mit vielen
              Sonnenstunden und moderaten Niederschlägen und kalte, schneearme Winter geprägt ist, schmeckt man den
              Weinen, die hier entstehen, auch an: fruchtig, kräftig, harmonisch. Die bestimmte Herkunft, das spezielle
              Terroir verleiht den Weinen seine ganz besondere Note. Im Weinbaugebiet Neusiedlersee finden sich nicht
              nur sandig lehmige Böden, sondern auch kalkreiche Schotterböden bis zu salzigen, leicht sandigen Böden,
              die ebenso zu einer bestimmten Typizität der Weine beitragen.
            </Paragraph>

            <Heading>Lagen und Rieden – Wo Hügel zu Bergen werden</Heading>
            <Paragraph>
              Rund um Gols finden wir neben der flachen Ebene, dem Heideboden, auch Hanglagen zwischen der Parndorfer
              Platte und dem Seewinkel, wie den berühmten Goldberg, wo auch unser Zweigelt wächst, als auch den
              Ungerberg mit herrlichem Blick auf den Neusiedlersee.
            </Paragraph>
            <ButtonArrow link="/Weine/" text="Zu unserem Weinsortiment" />
          </Left>
          <Right>
            <Portrait
              critical={true}
              image={lagenImage2.childImageSharp.gatsbyImageData}
              alt="Weingut Göschl - Lagen"
            />
            <Portrait critical={true} image={lagenImage.childImageSharp.gatsbyImageData} alt="Weingut Göschl - Lagen" />
          </Right>
        </Container>
        <Newsletter />
      </React.Fragment>
    )
  }
}

export default Lagen

export const query = graphql`
  {
    lagenImage: file(relativePath: { eq: "photos/weingut-goeschl-lagen-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    lagenImage2: file(relativePath: { eq: "photos/weingut-goeschl-lagen-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    watercolorImage: file(relativePath: { eq: "photos/background-watercolor.png" }) {
      childImageSharp {
        gatsbyImageData(width: 190, quality: 80, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
`
