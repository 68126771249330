import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import StyledLink from './styles/StyledLink'

import arrow from '../images/arrow.svg'

// const Container = styled.a`
//   display: flex;
// `

const Arrow = styled.img`
  margin-left: 12px;
  transition: 0.2s ease-out;
`
const Container = styled.span`
  :hover ${Arrow} {
    transform: translateX(3px);
  }
`

const ButtonArrow = ({ text, link }) => {
  return (
    <Link to={link}>
      <Container>
        <StyledLink>{text}</StyledLink>
        <Arrow src={arrow} alt="" />
      </Container>
    </Link>
  )
}

export default ButtonArrow
